import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import {
  AddToCart,
  Layout,
  Grid,
  ProductHeader,
  MerchProductHeader,
  MerchProductGrid,
  MerchProductGridItem,
  IngredientsBanner,
  ReviewsBanner,
  OtherProductCell,
  VarietyPackBanner,
  MidBanner,
  MerchTwoUp
} from '@components'
import { WithData, rgbaColorFromKey } from '@utilities'
import { typeStyle } from '@theme'

interface ProductProps {
  data: GatsbyTypes.MerchPageQuery
}



const MerchPage = ({
  data: {
    sanityProduct,
    otherSanityProducts,
    allShopifyProduct,
    sanitySiteSettings,
  },
}: ProductProps) => {
  
  if (
    !sanityProduct ||
    !otherSanityProducts ||
    !allShopifyProduct ||
    !sanitySiteSettings
  )
    return <></>


  const {
    slug,
    title,
    summary,
    merchImageMain,
    merchTwoUpFirst,
    merchTwoUpSecond,
    merchAvailableColorsSelector,
    reviewsAndPix,
    centerReviewImage,
    mainColor,
    mainColorForeground,
    altColor,
    altColorForeground,
    type
  } = sanityProduct


  const findAllVariants = (handle?: string) => {
    const d = allShopifyProduct.nodes.find((p) => p.handle === handle)
    return d?.variants
  }

  const findProductVariant = (handle?: string) => {
    const d = allShopifyProduct.nodes.find((p) => p.handle === handle)
    return d?.variants?.[0]
  }


  return (
    <Layout>
      <Helmet>
        <title>Bawi Agua Fresca: {title}</title>
      </Helmet>
      <Grid>
        <MerchProductHeader
          canImage={merchImageMain}
          variants={findAllVariants(slug?.current) as GatsbyTypes.Maybe<GatsbyTypes.ShopifyProductVariant>[]}
          {...{
            title,
            summary,
            slug,
            merchAvailableColorsSelector,
            mainColor,
            mainColorForeground,
            altColor,
            altColorForeground,
          }}
        />
      
        <MerchTwoUp mainColor={mainColor} image1={merchTwoUpFirst?.asset?.url} image2={merchTwoUpSecond?.asset?.url}/>

        <ShopOtherProducts>
          <Title>
            Shop Other Products
          </Title>
        </ShopOtherProducts>  
        <MerchProductGrid>
          {Array.from(otherSanityProducts.nodes).map(
            (otherProduct) => 
              !!otherProduct && (
                <MerchProductGridItem variant={findProductVariant(otherProduct.slug?.current) as GatsbyTypes.Maybe<GatsbyTypes.ShopifyProductVariant>} availableColors={otherProduct?.merchAvailableColorsSelector as string[]} product={otherProduct as Partial<GatsbyTypes.SanityProduct>} key={otherProduct._id}/>
              )
          )}
        </MerchProductGrid>
        {reviewsAndPix && reviewsAndPix.length > 0 && (
          <ReviewsBanner
            {...{
              reviewsAndPix,
              mainColor,
              mainColorForeground,
              altColor,
              centerReviewImage,
            }}
          />
        )}
      </Grid>
    </Layout>
  )
}

const mapDataToProps = (data: GatsbyTypes.MerchPageQuery) => ({ ...data })

export const query = graphql`
  query MerchPage($slug: String!) {
    sanitySiteSettings {
      ...siteSettingsFields
    }
    sanityProduct(slug: { current: { eq: $slug } }) {
      _key
      title
      type
      summary {
          _rawSpanish
          _rawSpanglish
          _rawEnglish
        }
      slug {
        current
      }
      summary {
        _rawSpanish
        _rawSpanglish
        _rawEnglish
      }
      merchImageMain {
        asset {
          url
        }
      }
      merchTwoUpFirst {
        asset {
          url
        }
      }
      mainColor
      mainColorForeground
      altColor
      altColorForeground
      merchTwoUpSecond {
        asset {
          url
        }
      }
      centerReviewImage {
      asset {
        url
      }
    }
      merchAvailableColorsSelector
      reviewsAndPix {
      ... on SanityReview {
        _key
        _type
        backgroundColor
        foregroundColor
        name
        stars
        review {
          _rawEnglish
          _rawSpanglish
          _rawSpanish
        }
        flipImage {
          asset {
            url
          }
        }
      }
    }
    }
    otherSanityProducts: allSanityProduct(filter: { type: { eq: "accessory" } }) {
      nodes {
        _id
        _key
        slug {
          current
        }
        type
        title
        merchAvailableColorsSelector
        merchImageMain {
          asset {
            url
          }
        }
        merchGridCardImage {
          asset {
            url
          }
        }
        canImageWet {
          asset {
            url
          }
        }
        accentSticker
        mainColor
        mainColorForeground
        altColor
        altColorForeground
        summary {
          _rawSpanish
          _rawSpanglish
          _rawEnglish
        }
      }
    }
    allShopifyProduct {
      nodes {
        title
        handle
        variants {
          price
          storefrontId
          inventoryQuantity
          title
        }
      }
    }
  }
`

const StyledAddToCart = styled((props) => <AddToCart {...props} />)<{
  altColor?: string
}>`
  @media only screen and (max-width: 743px) {
    > * {
      padding: 25px;
      grid-column-end: span 1;
    }
    div:nth-of-type(2) {
      display: flex;
      flex-direction: column-reverse;
      ${typeStyle.footnote}
      font-size: 20px;
      color: ${(props) => rgbaColorFromKey(props.altColor, 1)};
      > div {
        margin-bottom: 1em;
      }
    }
  }
`

const ShopOtherProducts = styled.div`
  height: 166px;
  width: 100%;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  @media only screen and (min-width: 744px) {
    grid-column-end: span 12;
  }
 
`

const Title = styled.h1`
  ${typeStyle.header}
  text-align: center;
  color: black;
  @media only screen and (min-width: 744px) {
   font-size: 52px;
   margin: 0;
   padding: 0;
  }
  
`
export default WithData(mapDataToProps, MerchPage)
